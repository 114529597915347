import React, { useEffect, useState } from "react";
import logoImg from "../../assets/images/Image1.png";
import Locale from "../../translations";
import UserSetting from "../UsetSetting/UserSetting";
import { NavLink, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCompanyDispatch, useCompanyState } from "../../context/global";
import Settings from "../../assets/images/settings.svg";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ShowForPermission from "../../helpers/showForPermission";
import {
  allNotifications,
  permissionsReload,
  readNotification,
} from "../../services/yarvel";

import arLogo from "../../assets/images/ar-flag.svg";
import enLogo from "../../assets/images/en-flag.svg";
import MobileHeader from "./mobileHeader";
function HeaderCheckMe() {
  const { translate } = Locale;
  /** */

  const { pathname } = useLocation();
  const userType =
    localStorage.getItem("userType"); /*localStorage.getItem("userType") */
  const company_name = localStorage.getItem("company_name"); 

  const dispatch = useCompanyDispatch();
  let history = useHistory();
  const location = useLocation();
  /******************************************/
  let CompanyState = useCompanyState();
  const { currentLanguage, enabledModules } = useCompanyState();

  const [perms, setPerms] = useState({});

  useEffect(() => {
    async function fetchLookups() {
      const res = await permissionsReload();
      dispatch({ type: "getPermissions", payload: res?.data });
    }
    fetchLookups();
  }, []);

  // useEffect(() => {
  //   //debugger
  //   if (perms) {

  //     dispatch({ type: "getPermissions", payload: perms });
  //     //  localStorage.setItem("permissions", JSON.stringify(perms.permissions));
  //     // console.log("t10", perms.permissions);
  //     // localStorage.setItem("userType", perms.user_type);
  //   }
  // }, [perms]);

  /******************************************/
  /************Notification************ */
  const [notifyData, setNotifyData] = useState();
  useEffect(() => {
    async function fetchData() {
      const res = await allNotifications();
      setNotifyData(res?.data?.data);
    }
    fetchData();
  }, []);

  const readNotify = async (id1) => {
    const res = await readNotification(id1);
  };
  /****** */

  let notificationLength = notifyData?.filter((data) =>
    Object.keys(data).find((key) => data["read_at"] === null)
  );

  /******* */
  let arr = [];
  let notification =
    notifyData?.length > 3
      ? (arr = notifyData.slice(0, 3))
      : (arr = notifyData);

  const AllNotification =
    arr?.length > 0 ? (
      arr.map((data, index) => (
        <DropdownItem
          tag="button"
          className={`d-flex justify-content-start flex-wrap py-2 border-top DropdownItem ${
            data.read_at == null ? "not-read text-primary" : "read"
          }`}
          onClick={() => {
            readNotify(data.id);
            setTimeout(() => {
              window.location.reload();
            }, 750);
            //history.push("/notification");
            history.push(`${data.url}`);
          }}
        >
          {currentLanguage == "en" ? (
            <h5>{data?.title.slice(0, 25)}...</h5>
          ) : (
            <h5>{data?.title.slice(0, 25)}...</h5>
          )}

          <br />
          <p>{data.body.slice(0, 29)}....</p>
        </DropdownItem>
      ))
    ) : (
      <DropdownItem tag="button" className="d-flex justify-content-center py-2">
        <p>No Notification</p>
      </DropdownItem>
    );

  /****************************************** */

  ///Show class navber

  const [show, setshow] = useState(false);

  const width = window.innerWidth;

  // && location.pathname !== "/changePassord"
  if (userType != null) {
    /*userType != null || undefined*/
    return (
      <>
        {true && width > 768 ? ( //&& width > 768
          <div className="header-container  ">
            <header className="header  text-white">
              <p className="container  py-1 mb-0">
                {translate.welcome}
                {"  "}
                {"  "} {company_name  ? company_name : null}
              </p>
            </header>
            <div className="w-100 bg-white pt-2 header-containt">
              <div className="container p-0  ">
                <div className="d-flex justify-content-between align-items-baseline header-items">
                  <figure className="logo ">
                    <img className="w-100" src={logoImg} alt="logo" />
                  </figure>
                  <div className="header-icons d-flex  justify-content-end">
                    {/* <UserSetting /> */}
                    {/***************************  Start Notification   **************************************************** */}
                    <UncontrolledDropdown
                      setActiveFromChild
                      className="notification "
                    >
                      <DropdownToggle
                        tag="button"
                        className="h5 text-primary d-flex align-items-center"
                        //caret
                      >
                        <i class="fas fa-bell notify-icon">
                          <span className="notify-number">
                            {" "}
                            {/* {notifyData?.length}{" "} */}
                            {notificationLength?.length}
                          </span>
                        </i>
                        {translate.notification}
                      </DropdownToggle>
                      <DropdownMenu className="bg-notify no-padding notify-items ">
                        {AllNotification}
                        <DropdownItem
                          tag="button"
                          onClick={() => {
                            history.push("/notification");
                          }}
                          className="d-flex justify-content-center w-100 notify-show-more py-2"
                        >
                          <h6> {translate.showMore} </h6>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {/***************************  End Notification   **************************************************** */}

                    <UncontrolledDropdown setActiveFromChild>
                      <DropdownToggle
                        tag="button"
                        className="h5 text-primary d-inline settings-head"
                        caret
                      >
                        <i className="fas fa-cog fa-lg  mx-2 text-primary "></i>
                        {"  "}
                        <span className="Header-Setting-title">
                          {translate.setting}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="settings">
                        <DropdownItem
                          tag="button"
                          onClick={() => {
                            dispatch({ type: "setLocale", payload: "ar" });
                          }}
                          className="d-flex justify-content-start"
                        >
                          <img
                            className="mx-1 px-2"
                            src={arLogo}
                            width="40"
                            alt="lang"
                          />
                          <span
                            style={{ dispaly: "inline-block", width: "25px" }}
                          >
                            العربية
                          </span>
                        </DropdownItem>

                        <DropdownItem
                          tag="button"
                          onClick={() => {
                            dispatch({ type: "setLocale", payload: "en" });
                          }}
                          className="d-flex justify-content-start"
                        >
                          <img
                            className="mx-1 px-2"
                            src={enLogo}
                            width="40"
                            alt="lang"
                          />
                          <span
                            style={{ dispaly: "inline-block", width: "25px" }}
                          >
                            English
                          </span>
                        </DropdownItem>

                        <DropdownItem
                          tag="button"
                          onClick={() => {
                            history.push(`/changePassord`);
                          }}
                        >
                          {translate.changePassword}
                          <i className="mx-2"></i>
                        </DropdownItem>
                        {/****** */}
                        <DropdownItem
                          tag="button"
                          onClick={() => {
                            dispatch({ type: "logout", payload: "logout" });
                          }}
                        >
                          {translate.logout}
                          <i
                            class="fas fa-sign-out-alt mx-2"
                            style={{ fontSize: "16px" }}
                          ></i>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </div>

            <nav className="header ">
              <div className="d-flex justify-content-between align-items-stretch  container p-0 ">
                <div className="d-flex  ">
                  <NavLink
                    exact={true}
                    to="/CustomersList/dashboard/DashboardCompanies"
                    activeClassName="is-active"
                    className="mr-4 py-2 text-bold"
                  >
                    {translate.MainMenu}
                  </NavLink>

                  {enabledModules?.includes("saudi-pcr") ? (
                    <ShowForPermission permission={"company:list-travellers"}>
                      <NavLink
                        exact={true}
                        to="/Umrah/Medical/Requests/orders"
                        activeClassName="is-active"
                        isActive={() =>
                          pathname.includes("/Umrah/Medical/Requests/")
                        }
                        className="mr-4 py-2  text-bold"
                      >
                        {translate.ComanyMedicalRequest}
                      </NavLink>
                    </ShowForPermission>
                  ) : (
                    <ShowForPermission permission={"company:list-travellers"}>
                      <NavLink
                        exact={true}
                        to="/CustomersList/orders"
                        activeClassName="is-active"
                        className="mr-4 py-2  text-bold"
                      >
                        {translate.ComanyMedicalRequest}
                      </NavLink>
                    </ShowForPermission>
                  )}

                   {enabledModules?.includes("saudi-pcr") ? (
                    <NavLink
                      exact={true}
                      to="/pilgrims/lated"
                      activeClassName="is-active"
                      isActive={() => pathname.includes("/pilgrims/lated")}
                      className="mr-4 py-2  text-bold"
                    >
                      {translate.pilgrimsLated}
                    </NavLink>
                  ) : null} 

                  <ShowForPermission permission={"company:settings"}>
                    <NavLink
                      exact={true}
                      to="/seting"
                      activeClassName="is-active"
                      className="mr-4 py-2 text-bold"
                    >
                      {translate.setting}
                    </NavLink>
                  </ShowForPermission>

                  <ShowForPermission permission={"company:list-settlements"}>
                    <NavLink
                      exact={true}
                      to="/PaymentCheck"
                      activeClassName="is-active"
                      className="mr-4 py-2 text-bold"
                    >
                      {translate.Payments}
                    </NavLink>
                  </ShowForPermission>

                  <ShowForPermission permission={"company:list-services"}>
                    <NavLink
                      exact={true}
                      to="/Servics"
                      activeClassName="is-active"
                      className="mr-4 py-2 text-bold"
                    >
                      {translate.services}
                    </NavLink>
                  </ShowForPermission>

                  <ShowForPermission
                    permission={["company:list-groups", "company:list-users"]}
                  >
                    {(CompanyState.permissions?.includes(
                      "company:list-groups"
                    ) &&
                      CompanyState.permissions?.includes(
                        "company:list-users"
                      )) ||
                    CompanyState.permissions?.includes("company:list-users") ? (
                      <NavLink
                        exact={true}
                        to="/team-management/users"
                        activeClassName="is-active"
                        className="mr-4 py-2 text-bold"
                      >
                        {translate.teamManagement}
                      </NavLink>
                    ) : (
                      <NavLink
                        exact={true}
                        to="/team-management/groups"
                        activeClassName="is-active"
                        className="mr-4 py-2 text-bold"
                      >
                        {translate.teamManagement}
                      </NavLink>
                    )}
                  </ShowForPermission>
                </div>
              </div>
            </nav>

            {/* <nav class={`navbar navbar-expand-lg   header ${show?"show-nav":""}`}  id="sidebar">
              <button
                class="navbar-toggler text-white m-0"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={()=>{
                  setshow(!show)
                }}
              >
                <i class="fas fa-bars" style={{fontSize:"28px"}}></i>
              </button>

              <div class={`collapse navbar-collapse   ${show?"show":""}`} id="navbarSupportedContent">
                <ul class="navbar-nav container"  >
                  <div className="d-flex justify-content-between align-items-baseline  p-0 ">
                    <div className="d-flex  navbar-links ">
                      <NavLink
                        exact={true}
                        to="/CustomersList/dashboard/DashboardCompanies"
                        activeClassName="is-active"
                        className="mr-4 py-2 text-bold"
                        onClick={()=>{
                          setshow(false)
                        }}
                      >
                        {translate.MainMenu}
                      </NavLink>
                      <ShowForPermission permission={"company:list-travellers"}>
                        <NavLink
                          exact={true}
                          to="/CustomersList/orders"
                          activeClassName="is-active"
                          className="mr-4 py-2  text-bold"
                          onClick={()=>{
                            setshow(false)
                          }}
                        >
                          {translate.ComanyMedicalRequest}
                        </NavLink>
                      </ShowForPermission>

                      <ShowForPermission permission={"company:settings"}>
                        <NavLink
                          exact={true}
                          to="/seting"
                          activeClassName="is-active"
                          className="mr-4 py-2 text-bold"
                          onClick={()=>{
                            setshow(false)
                          }}
                        >
                          {translate.setting}
                        </NavLink>
                      </ShowForPermission>

                      <ShowForPermission
                        permission={"company:list-settlements"}
                      >
                        <NavLink
                          exact={true}
                          to="/PaymentCheck"
                          activeClassName="is-active"
                          className="mr-4 py-2 text-bold"
                          onClick={()=>{
                            setshow(false)
                          }}
                        >
                          {translate.Payments}
                        </NavLink>
                      </ShowForPermission>

                      <ShowForPermission permission={"company:list-services"}>
                        <NavLink
                          exact={true}
                          to="/Servics"
                          activeClassName="is-active"
                          className="mr-4 py-2 text-bold"
                          onClick={()=>{
                            setshow(false)
                          }}
                        >
                          {translate.services}
                        </NavLink>
                      </ShowForPermission>

                      <ShowForPermission
                        permission={[
                          "company:list-groups",
                          "company:list-users",
                        ]}
                      >
                        {(CompanyState.permissions?.includes(
                          "company:list-groups"
                        ) &&
                          CompanyState.permissions?.includes(
                            "company:list-users"
                          )) ||
                        CompanyState.permissions?.includes(
                          "company:list-users"
                        ) ? (
                          <NavLink
                            exact={true}
                            to="/team-management/users"
                            activeClassName="is-active"
                            className="mr-4 py-2 text-bold"
                            onClick={()=>{
                              setshow(false)
                            }}
                          >
                            {translate.teamManagement}
                          </NavLink>
                        ) : (
                          <NavLink
                            exact={true}
                            to="/team-management/groups"
                            activeClassName="is-active"
                            className="mr-4 py-2 text-bold"
                            onClick={()=>{
                              setshow(false)
                            }}
                          >
                            {translate.teamManagement}
                          </NavLink>
                        )}
                      </ShowForPermission>
                    </div>
                  </div>
                </ul>
              </div>
            </nav> */}
          </div>
        ) : true && width <= 768 ? (
          <MobileHeader />
        ) : null}
      </>
    );
  } else {
    return "";
  }
}
export default HeaderCheckMe;
